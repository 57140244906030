import { Link } from 'react-router'
import * as React from 'react'
import { Button, type ButtonProps } from './button'
import { useClientLang } from '~/utils/i18n.utils'
import { cn } from '~/utils/misc'

export const LinkButton = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & {
		to: string
		prefetch?: 'intent' | 'render' | 'none' | 'viewport'
		preventScrollReset?: boolean
		target?: '_blank' | '_self' | '_parent' | '_top'
		viewTransition?: boolean
		external?: boolean
	}
>(
	(
		{
			className,
			children,
			to,
			prefetch = 'none',
			external = false,
			preventScrollReset = false,
			target = '_self',
			viewTransition = false,
			...props
		},
		ref,
	) => {
		const lang = useClientLang()
		return (
			<Button {...props} asChild ref={ref}>
				<Link
					className={cn('flex justify-center gap-4', className)}
					to={
						external || lang.lngShort === 'sv' ? to : `/${lang.lngShort}${to}`
					}
					prefetch={prefetch}
					preventScrollReset={preventScrollReset}
					target={target}
					viewTransition={viewTransition}
				>
					{children}
				</Link>
			</Button>
		)
	},
)
LinkButton.displayName = 'ButtonLink'
